import React, {useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Checkbox,
    CircularProgress
} from "@material-ui/core";
import {RbButton} from "../rb_button/rb_button";
import "./sign_up_modal.scss";
import {REST} from "../../../services/rest";
import {useAgentLink} from "../hooks/get_agent_link";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import MuiPhoneNumber from 'material-ui-phone-number';
import SweetAlert from "react-bootstrap-sweetalert"
import {savedListingApi} from "../../../services/api/saved_listing";
import {updateSessionInfo} from "../../../services/session";
import {ISignInSubmitForm} from "../hooks/use_sign_in_form";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {facebookSignIn} from "../../../services/api/facebook_sign_in";
import {agentInfoApi} from "../../../services/api/agent_info";
import {Validations} from "../../utils/validations";
import {privacyPolicyURL, termsOfUseURL} from "../../../services/constants";

export function SignUpModal(props: ISignUpModal) {
    const {open, handleClose, saveToFavorites, landingURI, listingId, handleSuccess, submitSignInForm} = props;
    const {agentLinkId} = useAgentLink();
    const [rbAgreement, setRbAgreement] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertContent, setAlertContent] = useState("");
    const [alertType, setAlertType] = useState("success");
    const formValues = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        password: "",
        rep_password: "",
        role: "renter",
        agent: agentLinkId,
        external_client: true,
        add_to_favorite: saveToFavorites,
        landing_uri: landingURI,
        listing_id: listingId
    };

    const validationSchema = Yup.object().shape({
        first_name: Validations.name,
        last_name: Validations.name,
        phone: Validations.phone,
        email: Validations.email,
        password: Validations.password,
        rep_password: Validations.password,
    });

    const handleSignUpSucces = async (newUser) => {
        await updateSessionInfo();
        if(newUser && newUser.status === 200) {
            if (saveToFavorites) {
                const favResp = await savedListingApi.post({
                    listingId: saveToFavorites,
                    liked: true,
                    contextListings: [],
                }).catch(e => {
                    console.error(e);
                });
                console.log({favResp})
                handleSuccess && handleSuccess();
            }
        }
    };

    const handleUserSignup = async (values, params) => {
        delete values["rep_password"];

        const agentInfoRes = await agentInfoApi.get(agentLinkId, false);
        values.partner_key = agentInfoRes.partner_key;
        values.target_zipcode = agentInfoRes.lead_zip_code;

        setLoading(true);
        setRbAgreement(false);
        const newUserRes = await REST.post('/agent_branded/cp', values, {
            headers: {
                "Accept": "*/*;q=0.5, text/javascript, application/javascript, application/ecmascript, application/x-ecmascript"
            }
        });

        if (newUserRes.data.code !== 200) {
            const errorMsg = newUserRes.data.error?.message;
            if (errorMsg) {
                let errorText = `${errorMsg.error_message}`;
                setAlertType("error");
                errorText = newUserRes.data.error.key == "user_exists" && errorMsg.fields ? ` User with these fields already exists:  ${errorMsg.fields?.map(field => field.field)}` : errorText;
                setAlertContent(errorText);
                setAlertVisible(true);
                params.resetForm();
            }
        } else {
            setAlertType("success");
            setAlertContent("User Created!");
            setAlertVisible(true);
            params.resetForm();
            handleSignUpSucces(newUserRes)
        }
    };

    const handleFacebookSignUp = async (facebookRes) => {
        const rbFacebookParams = {
            facebook_id: facebookRes.userID,
            email: facebookRes.email,
            first_name: facebookRes.first_name,
            agent_id: agentLinkId
        };
        const rbFacebookRes = await facebookSignIn.post(rbFacebookParams)
        if (rbFacebookRes.status === 200) {
            setAlertType("success");
            setAlertContent("User Created!");
            setAlertVisible(true);
            handleSignUpSucces(rbFacebookRes);
        }
    };

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'xs'}
        className="signup-modal--container"
        fullWidth={false}>
        <Formik
            initialValues={formValues}
            onSubmit={handleUserSignup}
            validationSchema={validationSchema}
            validate={(values) => {
                if (values.password !== values.rep_password) {
                    return {rep_password: "Passwords dont match"}
                }
            }}
        >
            {({values, handleBlur, handleChange, errors, touched, setFieldValue}) => <Form>
                <DialogTitle id="form-dialog-title">Create Your Free Account</DialogTitle>
                <DialogContent className="inputs--container">
                    <TextField
                        onBlur={handleBlur}
                        error={Boolean(errors.first_name && touched.first_name)}
                        helperText={touched.first_name && errors.first_name}
                        name="first_name" label="First Name"
                        value={values.first_name}
                        onChange={handleChange}
                        autoComplete={"off"}
                    />
                    <TextField onBlur={handleBlur} error={Boolean(errors.last_name && touched.last_name)} helperText={touched.last_name && errors.last_name} name="last_name" label="Last Name" value={values.last_name} onChange={handleChange} autoComplete={"off"}/>
                    <MuiPhoneNumber
                        defaultCountry={'us'}
                        data-cy="user-phone"
                        placeholder="9999999999"
                        onlyCountries={['us']}
                        disableDropdown={true}
                        disableCountryCode={true}
                        // inputClass={'lm-mui-phone-input w-input'}
                        onBlur={handleBlur}
                        error={Boolean(errors.phone && touched.phone)}
                        helperText={touched.phone && errors.phone}
                        name="phone" label="Phone" type="tel" value={values.phone} onChange={newPhone => setFieldValue('phone', newPhone) } autoComplete={"off"}/>

                    <TextField onBlur={handleBlur} error={Boolean(errors.email && touched.email)} helperText={touched.email && errors.email} name="email" label="Email" type="email" value={values.email} onChange={handleChange} autoComplete={"off"}/>
                    <TextField onBlur={handleBlur} error={Boolean(errors.password && touched.password)} helperText={touched.password && errors.password} name="password" label="Create Password" type="password" value={values.password} onChange={handleChange} autoComplete={"off"}/>
                    <TextField onBlur={handleBlur} error={Boolean(errors.rep_password && touched.rep_password)} helperText={touched.rep_password && errors.rep_password} name="rep_password" label="Confirm Password" type="password" value={values.rep_password} onChange={handleChange} autoComplete={"off"}/>
                    <div className="agreement-text">
                        By signing up, you agree with our <a href={termsOfUseURL} target="_blank">TOS</a> and <a href={privacyPolicyURL} target="_blank">PP</a>.
                    </div>
                    <div className="disclaimer-text">
                        <Checkbox onChange={(e) => setRbAgreement(!rbAgreement)} checked={rbAgreement} size={"small"}/> You understand that by creating this account you will be contacted by a real estate agent for assistance. You agree to our Terms of Service and Privacy Policy.
                    </div>
                </DialogContent>
                <DialogActions className="dialog-actions--container">
                    <RbButton disabled={!rbAgreement} type="submit">
                        {
                            loading ?
                                <CircularProgress size={22}/> : "Sign Up"
                        }
                    </RbButton>
                    <p className="or-text" >or</p>
                    <RbButton className="sign-in-button"
                              onClick={() => submitSignInForm({
                                    actionFormRoute: "/legacy-login",
                                    landingURI: landingURI, // add agentId params
                                    listingId: listingId,
                              })}>
                        {
                            loading ?
                                <CircularProgress size={22}/> : "Sign in with email"
                        }
                    </RbButton>
                    <FacebookLogin
                        appId="168604984585"
                        className="sign-in-facebook-button"
                        callback={handleFacebookSignUp}
                        fields="name, email, first_name"
                        render={renderProps => (
                            <RbButton className="sign-in-facebook-button" onClick={renderProps.onClick}>
                                {
                                    loading || renderProps.isProcessing ?
                                        <CircularProgress size={22}/> : <span><i className="fab fa-facebook-square fb-icon" />Sign in with Facebook</span>
                                }
                            </RbButton>
                        )}
                    />
                </DialogActions>
                <SweetAlert
                    error={alertType === "error"}
                    success={alertType === "sucess"}
                    title={alertType === "success" ? "Success" : "Failed"}
                    onConfirm={()=>{
                        setAlertVisible(false);
                        setAlertContent("");
                        setLoading(false);
                        if (alertType === "success") {
                            handleClose();
                        }
                    }}
                    show={alertVisible}
                >
                    {alertContent}
                </SweetAlert>
                </Form>}
        </Formik>
    </Dialog>
}

export interface ISignUpModal {
    open: boolean;
    handleClose: () => void;
    submitSignInForm: (params: ISignInSubmitForm) => void;
    landingURI: string;
    saveToFavorites?: boolean;
    handleSuccess?: () => void;
    listingId?: number;
}
